import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function RedirectPage() {
    const navigate = useNavigate();
    const pathname = window.location.pathname;
    const params = useParams();
    const vanillaSite = `https://app.coffeeclozers.com`;
    
    useEffect(() => {
        if ( pathname.includes("properties-list") ) {
            const cityId = params.id;
            window.open(`${vanillaSite}/properties-list/${cityId}`, "_self");
        }
        else if ( pathname.includes("/properties") ) {
            const cityId = params.cityId;
            const id = params.id;
            window.open(`${vanillaSite}/properties/${cityId}/${id}`, "_self");
        }
        else if ( pathname.includes("/pricing") ) {
            window.open(`${vanillaSite}/pricing`, "_self");
        }
        else if ( pathname.includes("/login") ) {
            window.open(`${vanillaSite}/login`, "_self");
        }
        else if ( pathname.includes("/sign-up") ) {
            window.open(`${vanillaSite}/sign-up`, "_self");
        }
        else {
            window.open(`${vanillaSite}/home`, "_self");
        }
    }, [pathname, navigate, params.id, params.cityId, vanillaSite]);

    return (
        <div>
        </div>
    );
};

export default RedirectPage;