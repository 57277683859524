import React from 'react';
import ReactDOM from 'react-dom/client';
import ScrollToTop from './components/ScrollToTop';
import RedirectPage from './pages/RedirectPage';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit'
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import allReducers from './reducers';
import './styles/App.css';
import mixpanel from 'mixpanel-browser';
import './styles/Variables.css';
import { init as initFullStory } from '@fullstory/browser';
import { PostHogProvider} from 'posthog-js/react'
import TagManager from 'react-gtm-module';
const store = configureStore({ reducer: allReducers });

// Initialize Firebase
const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE).config);
getAnalytics(app);

mixpanel.init(JSON.parse(process.env.REACT_APP_MIXPANEL).key, {debug: false});
initFullStory({ orgId: JSON.parse(process.env.REACT_APP_FULLSTORY).orgId });

const options = {
	api_host: JSON.parse(process.env.REACT_APP_POSTHOG).host
};

const tagManagerArgs = {
    gtmId: JSON.parse(process.env.REACT_APP_GOOGLE).gtmId
};
TagManager.initialize(tagManagerArgs);

const routing = (
	<Provider
	  store={store}
	>
	  	<BrowserRouter>
			<PostHogProvider 
				apiKey={JSON.parse(process.env.REACT_APP_POSTHOG).key}
				options={options}
			>
				<ScrollToTop>
					<Routes>
						<Route path="/" element={<RedirectPage />} />
						<Route path="/my-cities" element={<RedirectPage />} />
						<Route path="/sample-market" element={<RedirectPage />} />
						<Route path="/sample-offers" element={<RedirectPage />} />
						<Route path="/state-properties-list/:id" element={<RedirectPage />} />
						<Route path="/properties-list/:id" element={<RedirectPage />} />
						<Route path="/properties/:cityId/:id" element={<RedirectPage />} />
						<Route path="/offers/:id" element={<RedirectPage />} />
						<Route path="/sent/:id" element={<RedirectPage />} />
						<Route path="/pricing" element={<RedirectPage />} />
						<Route path="/pay" element={<RedirectPage />} />
						<Route path="/profile" element={<RedirectPage />} />
						<Route path="/custom-plan" element={<RedirectPage />} />
						<Route path="/login" element={<RedirectPage />} />
						<Route path="/sign-up" element={<RedirectPage />} />
						<Route path="/password-forgotten" element={<RedirectPage />} />
						<Route path="/penny-for-thoughts" element={<RedirectPage />} />
						<Route path="/buy-login" element={<RedirectPage />} />
						<Route path="/buy-sign-up" element={<RedirectPage />} />
						<Route path="/choose-counties" element={<RedirectPage />} />
						<Route path="/preferences" element={<RedirectPage />} />
						<Route path="/quick-report" element={<RedirectPage />} />
						<Route path="/quick-report/:id" element={<RedirectPage />} />
						<Route path="/success" element={<RedirectPage />} />
						<Route path="/describe-you" element={<RedirectPage />} />
						<Route path="/how-did-you-hear" element={<RedirectPage />} />
						<Route path="/terms-conditions" element={<RedirectPage />} />
						<Route path="/privacy-policy" element={<RedirectPage />} />
						<Route path="/grant-subscription" element={<RedirectPage />} />
						<Route path="/favourites" element={<RedirectPage />} />
						<Route path="/choose-product" element={<RedirectPage />} />
						<Route path="/welcome" element={<RedirectPage />} />
						<Route path="/demo-confirmation" element={<RedirectPage />} />
						<Route path="/buy-box-setup" element={<RedirectPage />} />
						<Route path="/edit-buy-box" element={<RedirectPage />} />
						<Route path="/calculators" element={<RedirectPage />} />
						<Route path="/verify-email" element={<RedirectPage />} />
						<Route path="*" element={<RedirectPage />} />
					</Routes>
				</ScrollToTop>
			</PostHogProvider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	// <React.StrictMode> {
		routing
	// } </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();